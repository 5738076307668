<template>
  <div>
    <div class="shipin">
      <div>{{wang64}}</div>
      <img src="" alt="">
      <!-- <video
        src="../assets/let_it_go.mp4"
        width="350"
        height="150"
        poster="../assets/logo.jpg"
        controls
        preload
      ></video> -->
      <!-- <img src="../assets/202111261639.0402140899054111065.png" alt=""> -->
      <button @click="downloadIamge('http://localhost:8080/img/202111261639.0402140899054111065.e9e4ff9e.png',namm.slice(12))" >点击下载</button>
    </div>
    <div class="btn" @click="long">登录</div>
    <div class="btn" @click="wang">注册</div>
    <div class="btn" @click="cha">查询</div>
    <div class="btn" @click="teting">特停</div>
    <div class="btn" @click="sztp">上传图片</div>
    <!-- <input type="file" @onchange="sendFiles(files)"> -->
          <input
        type="file"
        @change="getdd($event)"
       
        class="shangc"
        id="flle"
      />
    
  </div>
</template>
<script>
export default {
    data(){
        return{
          tpzy:null,
          namm:"20211126103524华发人-首页_03.png",
          wang64:"123123132123",
        }
    },
    mounted(){
      // this.wang()
    },
    methods:{


downloadIamge(imgsrc, name) {//下载图片地址和图片名
   // 这里是获取到的图片base64编码,这里只是个例子哈，要自行编码图片替换这里才能测试看到效果
    // this.download()
    this.getBase64Image('http://wjsc.lelele.vip/file/202111300932130.9169019006889256.jpg', 'coopCachetImg')
},

// 第一个参数是图片的URL地址，第二个是转换成base64地址后要赋值给的img标签
getBase64Image () {
    let imageUrl = 'http://wjsc.lelele.vip/file/202111300932130.9169019006889256.jpg'
        var image = new Image()
       let thiss=this
        image.setAttribute('crossOrigin', 'anonymous')
image.src = imageUrl
			//image.onload为异步加载
			image.onload = () => {
				var canvas = document.createElement("canvas");
				canvas.width = image.width;
				canvas.height = image.height;
				var context = canvas.getContext('2d');
				context.drawImage(image, 0, 0, image.width, image.height);
				
				var quality = 0.8;
				//这里的dataurl就是base64类型
				var dataURL = canvas.toDataURL("image/jpeg", quality);//使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！					
          console.log(dataURL)
          thiss.wang64=dataURL
          // console.log(dataURL)
          thiss.download(dataURL)
          
        }
},
//下载图片
      download(dataURL) {

  // download(base64) {
                // let imgData = base64;//这里放需要下载的base64
                this.downloadFile('海报.png', imgData);
            },
            downloadFile(fileName, content) {
                let aLink = document.createElement('a');
                let blob = this.base64ToBlob(content); //new Blob([content]);
         
                let evt = document.createEvent("HTMLEvents");
                evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
                aLink.download = fileName;
                aLink.href = URL.createObjectURL(blob);
         
                // aLink.dispatchEvent(evt);
                aLink.click()
            },
              //base64转blob
              base64ToBlob(code) {
                let parts = code.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;
         
                let uInt8Array = new Uint8Array(rawLength);
         
                for (let i = 0; i < rawLength; ++i) {
                  uInt8Array[i] = raw.charCodeAt(i);
                }
                return new Blob([uInt8Array], {type: contentType});
            
      
      },
    
// https://blog.csdn.net/qq_35844177/article/details/79094249
          //上传图片返回url
    tjzptp() {
      console.log(this.tpzy);
      let forms = new FormData();
      forms.append("file", this.tpzy);
      //  console.log(ss)
     this.$axios.post("http://tpsc.lelele.vip/upload",forms).then((red) => {
        console.log(red);
      });
    },
    //添加作品时候的图片预览
    getdd(e) {
      this.tpzy = e.target.files[0];

      this.tjzptp();
    },

      sztp(){
          this.$axios.post("/sctp", `deptId=1&uname=123456`)
          .then((res) => {
           console.log(res)
          });
      },
      teting(){
          this.$axios.post("/detail", `deptId=1`)
          .then((res) => {
           console.log(res)
          });
      },
      wang(){
        this.$axios
          .post("/register", `username="王乐"&password="123456"&deptId=3&namm="123"`)
          .then((res) => {
           console.log(res)
          });
      },
            long(){
        this.$axios
          .post("/login", `username="王乐"&password="123456"`)
          .then((res) => {
           console.log(res)
          });
      },
            cha(){
        this.$axios
          .get("/category", {})
          .then((res) => {
           console.log(res)
          });
      }
    }
}
</script>
<style scoped>
.btn{
  width: 2rem;
  font-size: 0.2rem;
  height: 0.5rem;
  background-color: aqua;
  margin: 10px;
}
.shipin{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: beige;
}
</style>
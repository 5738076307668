import Vue from 'vue'
import VueRouter from 'vue-router'
import ome from '../views/Home.vue'
import xx from '../views/xx.vue'
import Home from '../views/Home.vue'
import Login from "../views/Login.vue"
import my from "../views/my.vue"
import xzz from "../views/xzz.vue"
import Registered from "../views/Registered.vue"
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    }, {
        path: '/xx',
        name: 'xx',
        component: xx
    },
    {
        path: '/xzz',
        name: 'xzz',
        component: xzz
    },
    {
        path: "/registered",
        name: "Registered",
        component: Registered
    }, {
        path: "/login",
        name: "Login",
        component: Login
    }, {
        path: '/',
        name: 'ome',
        component: Home
    },
    {
        path: '/my',
        name: 'my',
        component: my
    },

    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import ( /* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    routes
})

export default router
<template>
  <div class="bg">
      <!-- <div class="tcss">
        <div>{{loga}}</div>
        <div>{{lss}}</div>
      </div> -->
    <div class="titt">
      <div class="d1">用户信息</div>
    </div>
    <div v-if="wdlzt == 0">
      <div class="zhk">
        <div class="txt">m e</div>
        <div class="zhangh">
          <div class="name">{{ namm }}</div>
          <div class="zhua">账号:{{ zhangh }}</div>
        </div>
      </div>
      <div class="nei">
        <van-cell-group inset>
          <van-cell title="用户群聊" value="正在开发中" />
          <van-cell
            title="修改密码"
            value=""
            label="联系客服修改QQ:2689711040"
          />
        </van-cell-group>
      </div>
      <div class="tcc" @click="tuic">退出</div>
    </div>
    <div class="jzk" v-if="wdlzt==1">未登录<br>请点击下方按钮登录</div>

      <router-link to="/Login" v-if="wdlzt==1"><div class="tck">点击登录</div></router-link>

    <van-tabbar v-model="active" @change="wang" fixed>
      <van-tabbar-item icon="photo-o">图集</van-tabbar-item>
      <van-tabbar-item icon="contact">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 1,
      namm: "",
      zhangh: "",
      wdlzt:1,
      loga:null,
      lss:null,
    };
  },
  mounted() {
    this.loga=window.location.href; /* 获取完整URL */
    this.lss=window.location.host; /* 获取主机地址和端口号 */
    this.namm = window.localStorage.getItem("namm");
    this.zhangh = window.localStorage.getItem("zhangh");
    let totoken = window.localStorage.getItem("tokenid");
    let wang = window.localStorage.getItem("zhangh");
    if ((totoken == null && wang == null) || totoken == "") {
      // this.$router.push({
      //   path: "/login",
      // });
      this.wdlzt=1
    } else {
      this.wdlzt=0
      // this.wangm = wang;
    }
  },
  methods: {
    //点击退出按钮后删除账号信息，并跳转登录页
    tuic() {
      console.log(123);
      window.localStorage.removeItem("tokenid");
      window.localStorage.removeItem("zhangh");
      window.localStorage.removeItem("namm");
      this.$router.push({
        path: "/login",
      });
    },
    wang(a) {
      if (a == 0) {
        this.$router.push({
          path: `/`,
        });
        console.log(a);
      }
    },
  },
};
</script>
<style scoped>
.bg{
  background-color: #F7F8FA;
  position: fixed;
  width: 100%;
  height: 100%;
}
.jzk{
  position: fixed !important;
  z-index: 100;
  top: 5.5rem;
  left: 50%;
  width: 7rem;
  transform: translate(-50%);
  font-size: 0.4rem;
  color: rgb(148, 141, 141);
}
.nei {
  top: 4.2rem;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  height: 6rem;
  width: 7.1rem;
  /* background-color: wheat; */
}
.tck {
  width: 4rem;
  left: 50%;
  transform: translate(-50%);
  height: 1rem;
  background-color: rgb(87, 168, 243);
  position: absolute;
  top: 7rem;
  font-size: 0.5rem;
  border-radius: 20px;
  line-height: 1rem;
  color: rgb(255, 255, 255);
}
.tcc {
  width: 5rem;
  left: 50%;
  transform: translate(-50%);
  height: 1rem;
  background-color: tomato;
  position: absolute;
  bottom: 1.5rem;
  font-size: 0.5rem;
  border-radius: 20px;
  line-height: 1rem;
  color: wheat;
}
.tcss {
  width: 7rem;
  left: 50%;
  transform: translate(-50%);
  height: 3.2rem;
  background-color: tomato;
  position: absolute;
  bottom: 3rem;
  font-size: 0.3rem;
  border-radius: 20px;
  line-height: 1rem;
  color: wheat;
  z-index: 99999;
}
.zhua {
  position: absolute;
  width: 4.3rem;
  font-size: 0.25rem;
  left: 0.5rem;
  text-align: left;
  bottom: 0.2rem;
  color: rgb(87, 6, 6);
  /* background-color: coral; */
}
.name {
  /* background-color:chartreuse; */
  position: absolute;
  top: 0rem;
  width: 4.3rem;
  font-size: 0.4rem;
  left: 0.4rem;
  text-align: left;
  top: 0.3rem;
  font-weight: bold;
}
.zhk .zhangh {
  /* background-color: rgb(123, 129, 126); */
  width: 4.3rem;
  height: 1.6rem;
  position: absolute;
  left: 2.3rem;
  top: 0.5rem;
}
.zhk .txt {
  font-size: 0.5rem;
  line-height: 1.6rem;
  border-radius: 10px;
  color: white;
  background-color: rgb(87, 168, 243);
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  left: 0.7rem;
  top: 0.5rem;
}
.zhk {
  width: 7rem;
  height: 2.3rem;
  /* background-color: salmon; */
  top: 1.5rem;
  left: 50%;
  transform: translate(-50%);
  position: relative;
}
.titt {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 0.9rem;
  font-size: 0.4rem;
  line-height: 0.9rem;
  position: fixed;
  top: 0rem;
  z-index: 9;
}
.titt .d1 {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  font-weight: bold;
}
</style>
<template>

  <div class="bg">
   <div class="mceng" v-if="jiazai == 1"></div>
    <div class="xlk" v-if="xzsc==1">
      <div class="xia">
        <img src="../assets/xz.png" alt="" class="xzt">
        <div class="xzw" @click="xztp">下载</div>
        </div>
      <div class="shan">
                <img src="../assets/sc.png" alt="" class="xzt">
        <div class="xzw" @click="remove">删除</div>
      </div>
    </div>
      <div class="titt tits" v-if="xzsc==1">
      <div class="d1">已选择1项</div>
      <div class="d2"  @click="gbxs">×</div>
      </div>
    <div class="jzk" v-if="moni.length==0 && wdlzt==0">图片集为空<br>请点击右上方按钮上传</div>
    <div class="jzk" v-if="wdlzt==1">未登录<br>请登录后查看或上传</div>
    <div class="titt">
      <div class="d1">我的图集</div>
      <div class="d2" @click="sctp">+上传图片</div>
      <router-link to="/Login" v-if="wdlzt==1"><div class="d2">点击登录</div></router-link>
            <input
        type="file"
        style="cursor: pointer; display: none"
        @change="getdd($event)"
        accept="image/*"
        class="shangc"
        id="flle"
      />
    </div>
    <div class="zbzp">
      <div class="zuo">
        <div class="zpx" v-for="(val, i) in lf" :key="i">
          <img :src="`http://wjsc.lelele.vip/file/${val.uname}`" alt="" @click="fdtp(val.uname)"  @touchstart="gotouchstart" @touchmove="gotouchmove" @touchend="gotouchend" />
          <div  class="tpcc" :id="`dbg${val.uname}`" @click="djsj(val)" v-if="xzsc==1">
            <div class="fxk" :id="`dkk${val.uname}`" >
              <div class="duih" wang="kong" :id="`dh${val.uname}`">✔</div>
            </div>
          </div>
        </div>
      </div>
      <div class="you">
        <div class="zpx" v-for="(val, i) in rt" :key="i">
          <img :src="`http://wjsc.lelele.vip/file/${val.uname}`" alt="" @click="fdtp(val.uname)"  @touchstart="gotouchstart" @touchmove="gotouchmove" @touchend="gotouchend"/>
        <div  class="tpcc" :id="`dbg${val.uname}`" @click="djsj(val)" v-if="xzsc==1">
            <div class="fxk" :id="`dkk${val.uname}`" >
              <div class="duih" wang="kong" :id="`dh${val.uname}`">✔</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="didi"></div>       -->
    </div>

    <van-tabbar v-model="active" @change="wang" fixed>
      <van-tabbar-item icon="photo-o">图集</van-tabbar-item>
      <!-- <van-uploader>
        <van-tabbar-item
          icon="plus"
          style="margin-right: 10vw; margin-left: 10vw"
          >上传图片</van-tabbar-item
        >
      </van-uploader> -->
      <van-tabbar-item icon="contact">我的</van-tabbar-item>
    </van-tabbar>
  </div>


</template>
<script>
import { ImagePreview } from 'vant';
import { DOWLOAD_FILE} from '../assets/dowload.js'
import axios from 'axios'
import JSZip from 'jszip'
// eslint-disable-next-line no-unused-vars
import FileSaver from 'file-saver'
import { Notify } from 'vant';
export default {
  data() {
    return {
      xzsc:0,
      aas:null,
      jiazai:0,
      active: 0,
      namm: "未登录",
      toid: null,
      moni: [],
      lf: [],
      rt: [],
      tjtp:"",
      wdlzt:1,
      tpkd:1,
      xsp:[],
    };
  },
  mounted() {
    this.namm = window.localStorage.getItem("namm");
    this.toid = window.localStorage.getItem("tokenid");
    let totoken = window.localStorage.getItem("tokenid");
    let wang = window.localStorage.getItem("zhangh");
    if ((totoken == null && wang == null) || totoken == "") {
      // this.$router.push({
      //   path: "/login",
      // });
      this.wdlzt=1
    } else {
      this.wdlzt=0
      console.log(wang);
      
    }
    if(this.wdlzt==0){
      this.wdzp();
    }
    

        
  },
  methods: {
    remove(){
      
      for(var a=0;a<this.xsp.length;a++){
    //       // this.touchProxyDowload1(`${this.xsp[a]}`,a)
           this.$axios.post("/remove", `id=${this.xsp[a].id}`)
          .then((res) => {
           console.log(res)
          // console.log(a,this.xsp.length)
          if(a==this.xsp.length){
            this.wdzp()
            Notify({ type: 'success', message: '删除成功' });
            this.xzsc=0
      this.xsp=[]
          }
          });
          }



      },
    gbxs(){
      this.xzsc=0
      this.xsp=[]
      // this.xzsc=0
    },
    // xztp(){


    //     let fileList=[]
    //     for(var a=0;a<this.xsp.length;a++){
    //       // this.touchProxyDowload1(`${this.xsp[a]}`,a)
    //       fileList.push({
    //         path: 'http://wjsc.lelele.vip/file/'+this.xsp[a],
    //         fileName: this.xsp[a],
    //       })
          
    //     }
    //     console.log(fileList)


    // },

//下载图片
        /**
     * 下载按钮点击事件
     */
     xztp() {
      let fileList=[]
        for(var a=0;a<this.xsp.length;a++){
          // this.touchProxyDowload1(`${this.xsp[a]}`,a)
          fileList.push({
            path: 'http://wjsc.lelele.vip/file/'+this.xsp[a].uname,
            fileName: this.xsp[a].uname,
          })
          
        }
        const zip = new JSZip()
    let imgs = zip.folder("pic");
      const cache = {}
      const promises = []
      fileList.forEach(item => {
        console.log('item', item)
        // const promise = this.getFile(item.path).then(data => {
        //   // eslint-disable-next-line camelcase
        //   const file_name = item.fileName
        //   zip.file(file_name, item.path, { binary: true })
        //   cache[file_name] = data
        // })
        const promise = this.getFile(item.path).split(',')[1];
        imgs.file(item.fileName, promise, {base64: true});

        // console.log(promise)
        // promises.push(promise)
      })
      // Promise.all(promises).then(() => {
        zip.generateAsync({ type: "blob" }).then(content => {
          // 生成二进制流
          // FileSaver.saveAs(content, '文件下载.zip') // 利用file-saver保存文件  自定义文件名
          // eslint-disable-next-line no-undef
          // saveAs(content, '图片下载.zip') // 利用file-saver保存文件  自定义文件名
          const link = document.createElement('a');
    link.href = window.URL.createObjectURL(content);
    link.download = '图片下载.zip';
    link.click();
        })
      // })
      this.xsp=[]
      this.xzsc=0
    },
    /**
    //  * 获取文件
    //  * @param url 文件路径
    //  * @returns {Promise<unknown>}
     */

     getFile(imgs) {
      let img= new Image();
      img.src = imgs;
      console.log(img,123)
   let canvas = document.createElement("canvas");
   canvas.width = img.width;
   canvas.height = img.height;
   let ctx = canvas.getContext("2d");
   ctx.drawImage(img, 0, 0, img.width, img.height);
   let ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
   let dataURL = canvas.toDataURL("image/"+ext);
   console.log(dataURL)
   return dataURL;
},
      // ========================================================
    djsj(val){
      var dbg=document.getElementById(`dbg${val.uname}`)
      var dkk=document.getElementById(`dkk${val.uname}`)
      var dh=document.getElementById(`dh${val.uname}`)
      // console.log(dh.getAttribute(""))
      if(dh.getAttribute("wang")=='kong'){
        this.xsp.push(val);
        console.log(val)
        console.log(this.xsp)
        console.log(1)
        // console.log(dbg)
      dbg.style.backgroundColor="rgba(20, 20, 20, 0.6)"
      dkk.style.backgroundColor="#1296db"
      dh.style.display="block"
      dh.setAttribute("wang","you")      
      }else if(dh.getAttribute("wang")=='you'){
        
      console.log(2)
      dbg.style.backgroundColor="rgba(20, 20, 20, 0)"
      dkk.style.backgroundColor="white"
      dh.style.display="none"
      dh.setAttribute("wang","kong")
      for(var a=0;a<this.xsp.length;a++){
        if(this.xsp[a].uname==val.uname){
          console.log(a)
          this.xsp.splice(a, 1);
        }
      }
      console.log(this.xsp)
      }      
    },
    //手指按下
gotouchstart(){
   let that = this;
  //  clearTimeout(this.timeOutEvent);//清除定时器
   this.timeOutEvent = setTimeout(function(){
        //执行长按要执行的内容，
      // ...
      console.log(123)
      that.xzsc=1

     },600);//这里设置定时
 },
      //手释放，如果在500毫秒内就释放，则取消长按事件，此时可以执行onclick应该执行的事件
gotouchend(){
    clearTimeout(this.timeOutEvent);

},
//如果手指有移动，则取消所有事件，此时说明用户只是要移动而不是长按 
gotouchmove(){
     clearTimeout(this.timeOutEvent);//清除定时器
},

        fdtp(i){
        ImagePreview({
            images:[`http://wjsc.lelele.vip/file/${i}`],
            closeable: true,
            showIndex:false,
          });
    },
    sctp(){
      let fil = document.getElementById("flle");
      fil.click();
    },
      sztp(i){
          this.$axios.post("/sctp", `deptId=${this.toid}&uname=${i}`)
          .then((res) => {
            

           console.log(res)
           this.wdzp()
      Notify({ type: 'success', message: '上传成功' });
          });
      },
        //添加作品时候的图片预览
    getdd(e) {
      this.tpzy = e.target.files[0];
      let imgSrc = window.URL.createObjectURL(e.target.files[0]);
      this.tjtp = imgSrc;
console.log(imgSrc)


      this.tjzptp();
    },
        //上传图片返回url
    tjzptp() {
      console.log(this.tpzy);
      let forms = new FormData();
      forms.append("file", this.tpzy);
      //  console.log(ss)
     this.$axios.post("http://tpsc.lelele.vip/upload",forms).then((red) => {
        console.log(red.data.results);
        this.sztp(red.data.results)
        document.getElementById("flle").value=''
      });
    },
    //获取个人图片
    wdzp() {
            this.jiazai=1,
            console.log(this.toid)
      this.$axios.post("/detail", `deptId=${this.toid}`).then((res) => {
        console.log(res);
        this.lf=[]
        this.rt=[]
        this.moni = res.data.result;
        this.pbl();
                    this.jiazai=0
      });
    },
    wang(a) {
      if (a == 1) {
      this.$router.push({
        path: `/my`,
      });
        console.log(a);
      }
    },
    //把拿到的数据分开放到两个数组中
    pbl() {
      for (var i = 0; i < this.moni.length; i++) {
        if (i % 2 == 0) {
          this.lf.push(this.moni[i]);
        } else if (i % 2 == 1) {
          this.rt.push(this.moni[i]);
        }
      }
    },
  },
};
</script>

<style scoped>
/* .tpcc .fxk .duih{
  color: white !important;
}
.tpcc .fxk{

  background-color: #1296db !important;
}
.tpcc{

  background-color: rgba(20, 20, 20, 0.6) !important;
} */
.tpcc .fxk .duih{
  font-size: 0.7rem;
  position: absolute;
  line-height: 0.7rem;
  right: 0.05rem;
  color: white;
  display: none;
}
.tpcc .fxk{
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  width: 0.7rem;
  height: 0.7rem;
  background-color: white;
  border: 1px solid salmon;
}
.tpcc{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(20, 20, 20, 0);
  top: 0rem;
}
.xlk .shan .xzt{
  height: 0.6rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 0.1rem;
}
.xlk .shan .xzw{
  height: 0.7rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 0.65rem;
  font-size: 0.5rem;
  color: #d81e06;
}
.xlk .xia .xzt{
  height: 0.6rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 0.1rem;
}
.xlk .xia .xzw{
  height: 0.7rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 0.65rem;
  color: #1296db;
}
.xlk .xia{
  position: absolute;
  width: 50%;
  height: 1.5rem;
  /* background-color: rgb(150, 194, 194); */
  left: 0rem;
  font-size: 0.5rem;
}
.xlk .shan{
  position: absolute;
  width: 50%;
  height: 1.5rem;
  right: 0rem;
  /* background-color: rgb(212, 212, 212); */

}
.mceng{
  /* display: none; */
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(20, 20, 20, 0.6);
  z-index: 2 !important;
  /* bottom: 0.9rem; */
  /* top: 1rem; */
  /* bottom: 0.9rem; */

}
.jzk{
  position: fixed !important;
  z-index: 100;
  top: 50%;
  left: 50%;
  width: 7rem;
  transform: translate(-50%,-50%);
  font-size: 0.4rem;
  color: rgb(148, 141, 141);
}
.xlk{
  width: 100%;
  height: 1.5rem;
  bottom: 0rem;
  background-color: rgb(223, 219, 219);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* background-color: aqua; */
  position: fixed !important;
  z-index: 100;
}
.jzkk{
  position: fixed !important;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.zpx img {
  display: block;
  width: 100%;
}
.zpx {
  position: relative;
  width: 100%;
  /* height: 3rem; */
  margin-bottom: 0.15rem;
  /* margin-top: 0.15rem; */

  /* background-color: rosybrown; */
}
.zuo {
  width: 3.45rem;
  /* height: 6rem; */
  /* background-color:royalblue; */
  display: inline-block;
  position: relative;
  margin-bottom:1rem ;
}
.you {
  width: 3.45rem;
  /* height: 6rem; */
  display: inline-block;
  left: 0.1rem;
  position: relative;
  margin-bottom:1rem ;
  /* background-color:rgb(161, 168, 187); */
}
.zbzp {
  display: flex;
  /* background-color: aqua; */
  width: 7rem;
  position: relative;

  top: 1rem;
  left: 50%;

  transform: translate(-50%);

}
.didi {
  width: 100%;
  height: 1.5rem;
  position: relative;
  background-color: royalblue;
}
.titt .d1{
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  font-weight: bold;
}
.titt .d2{
  position: absolute;
  right: 0.2rem;
  font-size: 0.2rem;
  width: 1.25rem;
  height: 0.4rem;
  line-height: 0.4rem;
  top: 0.3rem;
  color: white;
  border-radius: 10px;
  background-color: rgb(88, 173, 212);

}
.tits {
  z-index: 10 !important;
  background-color: rgb(223, 219, 219) !important;
}
.tits .d2{
color: rgb(0, 0, 0);
border: none !important;
  /* background-color: rgb(223, 219, 219); */
  width: 0.6rem;
  height: 0.6rem;
  top: 0.1rem;
  right: 0.05rem !important;
  border-radius: 50%;
  background-color: rgb(228, 184, 184);
  line-height: 0.6rem;
  font-size: 0.8rem;
}
.titt {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 0.9rem;
  font-size: 0.4rem;
  line-height: 0.9rem;
  position: fixed;
  top: 0rem;
  z-index: 9;
}
.bg {
  overflow-Y: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #F7F8FA;

}
</style>



<template>
<div>
    <van-nav-bar title="登录">
      <template #right>
        <router-link to='/registered' style="color:#2196f3">注册
          <van-icon name="arrow" color="#2196f3" />
        </router-link>
      </template>
    </van-nav-bar>
    <van-form @submit="onSubmit">
        <van-field
            v-model="username"
            name="username"
            label="账号"
            placeholder="用户名"
            error-message-align="right"
            :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
            v-model="password"
            type="password"
            name="password"
            label="密码"
            placeholder="密码"
            error-message-align="right"
            :rules="[{ required: true, message: '请填写密码' }]"
        />
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
    </van-form>
    <van-popover
      v-model="showPopover"
      theme="dark"
      trigger="click"
      :actions="actions"
      
    >
    <template #reference>
        <van-button size="mini" round style="color:#2196f3">忘记密码</van-button>
    </template>
    </van-popover>
    </div>
</template>
<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      showPopover: false,
      actions: [{ text: '请您联系客服,' },{text:` QQ号:
      2689711040`}]
    };
  },
  methods: {
    onSubmit(i) {
      console.log(i)
        this.$toast.success('正在登录中');
         this.$axios
          .post("/login", `username=${i.username}&password=${i.password}`)
          .then((res) => {
           console.log(res)
              if(res.data.message=="登陆成功"){
                window.localStorage.setItem("tokenid", res.data.result.deptId);
            window.localStorage.setItem("zhangh", res.data.result.username);
            window.localStorage.setItem("namm", res.data.result.namm);
            
                this.$toast.success('登录成功正在跳转首页');
                // self.location.href="/login"
                this.$router.push({
                  path: `/`,
                });
              }else{
                this.$toast.success('账号或密码错误请重新输入');
              }
          });
        

        
    },
  },
};
</script>




import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Notify } from 'vant';

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
import axios from 'axios'
Vue.prototype.$axios = axios
axios.defaults.baseURL = 'http://tpjk.lelele.vip';
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
<template>
  <button @click="handleDownload">JSZip下载</button>
</template>
<script>
import axios from 'axios'
import JSZip from 'jszip'
// eslint-disable-next-line no-unused-vars
import FileSaver from 'file-saver'
export default {
  name: 'Download',
  data () {
    return {
      // 需要下载的文件，必须要有文件路径和文件名称
      fileList: [
        {path: 'http://localhost:8080/img/xz.7eaee942.png', fileName: 'img1.jpeg'},
        {path: 'http://localhost:8080/img/xz.7eaee942.png', fileName: 'img2.jpeg'},
        {path: 'http://localhost:8080/img/xz.7eaee942.png', fileName: 'img3.jpeg'},
        {path: 'http://localhost:8080/img/xz.7eaee942.png', fileName: '11.pdf'}
      ]
    }
  },
  methods: {
    /**
     * 下载按钮点击事件
     */
    handleDownload () {
      const zip = new JSZip()
      const cache = {}
      const promises = []
      this.fileList.forEach(item => {
        console.log('item', item)
        const promise = this.getFile(item.path).then(data => {
          // eslint-disable-next-line camelcase
          const file_name = item.fileName
          zip.file(file_name, item.path, { binary: true })
          cache[file_name] = data
        })
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: 'blob' }).then(content => {
          // 生成二进制流
          // FileSaver.saveAs(content, '文件下载.zip') // 利用file-saver保存文件  自定义文件名
          // eslint-disable-next-line no-undef
          saveAs(content, '文件下载.zip') // 利用file-saver保存文件  自定义文件名
        })
      })
    },
    /**
     * 获取文件
     * @param url 文件路径
     * @returns {Promise<unknown>}
     */
    getFile (url) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url,
          responseType: 'blob'
          // responseType: 'arraybuffer'
        })
          .then(data => {
            console.log('data', data)
            resolve(data.data)
          })
          .catch(error => {
            reject(error.toString())
          })
      })
    }
  }
}
</script>
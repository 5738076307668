<template>
    <div>
    <van-nav-bar title="注册">
      <template #right>
        <router-link to='/login' style="color:#2196f3">登录
            <van-icon name="arrow" color="#2196f3" />
        </router-link>
      </template>
    </van-nav-bar>
    <van-form validate-first @submit="onFailed">
        <van-field
        v-model="user"
        name="user"
        placeholder="请输入你的真实名字"
        label="用户名"
        :rules="[{ validator, message: '用户名只能为汉字不能有字母及特殊符号' }]"
    />
  <!-- 通过 pattern 进行正则校验 -->
    <van-field
        v-model="username"
        name="username"
        placeholder="长度6~16位不能有汉字及特殊符号"
        label="账号"
        :rules="[{ pattern, message: '账户格式错误请重新输入' }]"
    />
    <van-field
        v-model="password"
        name="password"
        placeholder="长度6~16位不能有汉字及特殊符号"
        label="密码"
        :rules="[{ pattern, message: '密码格式错误请重新输入' }]"
    />
     <van-button round block type="info" native-type="submit">提交</van-button>
    </van-form>
    </div>
</template>
<script>
export default {
    data(){
        return{
            ass:false,
            user:"",
            username:"",
            password:"",
            qrpassword:"",
            pattern:/^[A-Za-z0-9]{6,16}$/,
            pattn:/^[\u4e00-\u9fa5]{1,10}$/,
            cids:[],
            dqid:null,
        }
    },
    mounted(){
this.cha()
    },
    methods:{
        cha(){
        this.$axios
          .get("/id", {})
          .then((res) => {
           console.log(res)
           var cid=res.data.results
           console.log(cid)
            for(var i=0;i<cid.length;i++){
                this.cids.push(cid[i].deptId)
            }
            this.cids.sort(function(a,b){
                return b-a
            })
            this.dqid=this.cids[0]+1
            console.log(this.cids)
            console.log(this.dqid)
          });
      },
          validator(val) {
      return /^[\u4e00-\u9fa5]{1,10}$/.test(val);
    },
        validatr(a){
           
           return this.password==a
        },
        onFailed(i) {
        if(this.dqid!=null){
           this.$toast.success('正在注册中');
         this.$axios
          .post("/register", `username=${i.username}&password=${i.password}&deptId=${this.dqid}&namm=${i.user}`)
          .then((res) => {
           console.log(res)
           if(res.data.message=="注册成功"){           
            window.localStorage.setItem("tokenid", i.dqid);
            window.localStorage.setItem("zhangh", i.username);
            window.localStorage.setItem("namm", i.user);
           this.$toast.success('注册成功正在跳转登录页面');
             this.$router.push({
                  path: `/`,
                });
        }else{
           this.$toast.success('账号已经存在请重新输入');
        }
          });
            console.log(i)

        }else{
            alert("加载超时请退出软件在重新进入")
        }

        },
    }
   
}
</script>

